import { corpSraEdb } from "@telia/b2x-rest-client";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";

export const checkIfMigrationCandidate = async (groupId) => {
  try {
    const scopeId = await getScopeIdOrThrow();
    const response = await corpSraEdb.MyBusinessMigrationControllerService.checkIfMigrationCandidate(
      scopeId,
      groupId
    );
    return {
      migrationCandidate: response.migrationCandidate,
      masterGroupId: response.masterGroupId,
    };
  } catch (error) {
    return { migrationCandidate: false };
  }
};

export const getMigrationStatus = async (groupId) => {
  try {
    const scopeId = await getScopeIdOrThrow();
    const migrationStatus = await corpSraEdb.MyBusinessMigrationControllerService.getMigrationStatus(
      scopeId,
      groupId
    );
    return migrationStatus;
  } catch (error) {
    return null;
  }
};
