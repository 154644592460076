var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showMigrationModals
    ? _c(
        "div",
        {
          staticClass: "b2b-edb-sra-migration-modal",
          attrs: {
            "t-id": "b2b-edb-sra-migration-modal",
            "data-dont-collect": "",
          },
        },
        [
          _c("b2x-toast", {
            staticClass: "edb-sra-migration-toast",
            attrs: {
              "show-toast": _vm.showNotification,
              status: "information",
              "header-text": _vm.t("MIGRATE_LATER_TITLE"),
              "content-text": _vm.t("MIGRATE_LATER_DESCRIPTION"),
            },
            on: {
              closeToastEvent: function ($event) {
                return _vm.closeToasten()
              },
            },
          }),
          _vm.showModal
            ? _c(
                "b2x-modal",
                {
                  attrs: {
                    "modal-id": "b2b-edb-sra-migration-modal",
                    "is-open": _vm.showModal,
                  },
                  on: { closeModal: _vm.closeModal },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "modal-body" }, slot: "modal-body" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-column-centered" },
                        [
                          _c("h1", { staticClass: "h1-heading" }, [
                            _vm._v(_vm._s(_vm.t("MODAL_TITLE"))),
                          ]),
                          _c("telia-p", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.t("MODAL_TEXT")) +
                                "\n        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { attrs: { slot: "button-footer" }, slot: "button-footer" },
                    [
                      _c(
                        "div",
                        { staticClass: "button-block" },
                        [
                          _c("telia-button", {
                            attrs: {
                              variant: "primary",
                              text: _vm.t("MODAL_BUTTON_START"),
                            },
                            on: { click: _vm.startMigrationGuide },
                          }),
                          _c(
                            "div",
                            {
                              attrs: {
                                "t-id":
                                  "b2b-edb-sra-migration-modal-close-button",
                              },
                            },
                            [
                              _c(
                                "telia-link",
                                {
                                  attrs: {
                                    variant: "standalone",
                                    silent: "true",
                                  },
                                  on: { click: _vm.close },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.t("MODAL_BUTTON_CANCEL")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }