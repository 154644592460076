import Vue from "vue";
import wrap from "@telia/vue-web-component-wrapper-ie11";
import singleSpaVue from "single-spa-vue";
import App from "./b2b-edb-sra-migration-modal.vue";
import "@telia/b2x-modal";
import "@telia/b2x-toast";

Vue.config.ignoredElements = [...Vue.config.ignoredElements, /b2b-\w*/i];

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App);
    },
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

export function defineCustomElements() {
  const CustomElement = wrap(Vue, App, {
    globalStyles: true,
  });
  window.customElements.define("b2b-edb-sra-migration-modal", CustomElement);
}
