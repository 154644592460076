import { render, staticRenderFns } from "./b2b-edb-sra-migration-modal.vue?vue&type=template&id=be69bdc4&scoped=true&"
import script from "./b2b-edb-sra-migration-modal.vue?vue&type=script&lang=js&"
export * from "./b2b-edb-sra-migration-modal.vue?vue&type=script&lang=js&"
import style0 from "./b2b-edb-sra-migration-modal.vue?vue&type=style&index=0&id=be69bdc4&prod&lang=scss&scoped=true&"
import style1 from "./b2b-edb-sra-migration-modal.vue?vue&type=style&index=1&id=be69bdc4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../common/temp/node_modules/.pnpm/vue-loader@15.10.1_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be69bdc4",
  null
  
)

export default component.exports