<template>
  <div
    t-id="b2b-edb-sra-migration-modal"
    class="b2b-edb-sra-migration-modal"
    v-if="showMigrationModals"
    data-dont-collect
  >
    <b2x-toast
      :show-toast="showNotification"
      @closeToastEvent="closeToasten()"
      status="information"
      class="edb-sra-migration-toast"
      :header-text="t('MIGRATE_LATER_TITLE')"
      :content-text="t('MIGRATE_LATER_DESCRIPTION')"
    />

    <b2x-modal
      modal-id="b2b-edb-sra-migration-modal"
      v-if="showModal"
      :is-open="showModal"
      @closeModal="closeModal"
    >
      <div slot="modal-body">
        <div class="flex-column-centered">
          <h1 class="h1-heading">{{ t("MODAL_TITLE") }}</h1>
          <telia-p>
            {{ t("MODAL_TEXT") }}
          </telia-p>
        </div>
      </div>

      <div slot="button-footer">
        <div class="button-block">
          <telia-button
            variant="primary"
            :text="t('MODAL_BUTTON_START')"
            @click="startMigrationGuide"
          />
          <div t-id="b2b-edb-sra-migration-modal-close-button">
            <telia-link variant="standalone" silent="true" @click="close">
              {{ t("MODAL_BUTTON_CANCEL") }}
            </telia-link>
          </div>
        </div>
      </div>
    </b2x-modal>
  </div>
</template>

<script>
import { trackEvent } from "@telia/b2b-web-analytics-wrapper";
import { translateSetup, translateMixin } from "./locale";
import { checkIfMigrationCandidate, getMigrationStatus } from "./services/migration-service";
import { hasPermission } from "@telia/b2b-logged-in-service";
import { pathToRegexp } from "path-to-regexp";
translateSetup();

export default {
  name: "b2b-edb-sra-migration-modal",
  mixins: [translateMixin],
  data() {
    return {
      groupId: null,
      isMigrationCandidate: false,
      showModal: false,
      showNotification: false,
      masterGroupId: null,
    };
  },
  async created() {
    this.groupId = getGroupIdFromUrl();
    await this.initializeModalValues();
    if (this.showMigrationModals) {
      this.setClassOnBody(true);
      trackEvent("Customer_Information", "View", "Hantera_Användare_Sra");
    } else {
      this.setClassOnBody(false);
    }
  },
  computed: {
    showMigrationModals() {
      return (
        this.isMigrationCandidate &&
        (this.showModal || this.showNotification) &&
        this.linkUrl !== null &&
        this.onboardingWatched &&
        this.cookieConsent
      );
    },
    linkUrl() {
      if (this.masterGroupId) {
        return `/foretag/mybusiness/${this.masterGroupId}/hantera/edb-sra-migrering`;
      }
      return null;
    },
    onboardingWatched() {
      return window.localStorage.getItem("onboardingWatched") === "1";
    },
    cookieConsent() {
      return window.localStorage.getItem("cookieConsentTimestamp");
    },
    hasGroupId() {
      return this.groupId !== null;
    },
    isOnCots() {
      return window.location.hostname.indexOf("cots") >= 0;
    },
  },
  methods: {
    async initializeModalValues() {
      if (this.isOnCots || !this.hasGroupId || !(await hasBannerPUI()) || !enabledUrls()) {
        return;
      }
      const response = await checkIfMigrationCandidate(this.groupId);
      this.isMigrationCandidate = response.migrationCandidate;
      if (!this.isMigrationCandidate) {
        return;
      }
      this.masterGroupId = response.masterGroupId;

      const migrationStatus = await getMigrationStatus(this.groupId);

      if (!migrationStatus) {
        return;
      } else {
        const { scheduled, finished, wizardStarted, initiator } = migrationStatus;
        this.showModal = !wizardStarted || (wizardStarted && initiator && !scheduled && !finished);
      }
    },
    async startMigrationGuide() {
      window.location.href = this.linkUrl;
      trackEvent("Hantera_Användare_Sra", "Initiated", "Intro_Till_Guiden");
    },
    closeModal() {
      this.showModal = false;
      this.showNotification = true;
    },
    close() {
      // Set expires timestamp
      this.showNotification = true;
      this.showModal = false;
      this.setClassOnBody(false);
      trackEvent("Hantera_Användare_Sra", "Click", "Intro_Uppdatera_Senare");
    },
    setClassOnBody(add) {
      add
        ? window.document.body.classList.add("edb-sra-migration-body")
        : window.document.body.classList.remove("edb-sra-migration-body");
    },
    closeToasten() {
      this.showNotification = false;
      trackEvent("Hantera_Användare_Sra", "Click", "Reminder_Stäng");
    },
  },
};

function getGroupIdFromUrl() {
  const groupIdMatch = window.location.pathname.match(/5\d{8}/);
  if (groupIdMatch !== null) {
    return groupIdMatch[0];
  }
  return null;
}
async function hasBannerPUI() {
  try {
    return await hasPermission("SRA_MIGRATION_BANNER");
  } catch (error) {
    return false;
  }
}

function enabledUrls() {
  const scopeIdRegexp = pathToRegexp("/foretag/mybusiness/:scopeId(\\w+)");
  return scopeIdRegexp.exec(window.location.pathname) !== null;
}
</script>

<style lang="scss" scoped>
.edb-sra-migration-toast {
  position: absolute;
  bottom: 40px;
  right: 0;
  margin-right: 2rem;
}

.b2b-edb-sra-migration-modal {
  background-color: #f2f2f2;
}

.h1-heading {
  margin-bottom: 24px;
  font-size: 22px;
}

.flex-column-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.button-block {
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}

.migrate-later {
  max-width: 120rem;
  margin: 0 auto 0 auto;
  padding: 2rem 1.6rem 0 1.6rem;
}
</style>

<style lang="scss">
.edb-sra-migration-body {
  & [id="single-spa-application:@telia/b2b-edb-sra-migration-modal"] {
    z-index: 100000000;
  }
}

.edb-sra-migration-toast .telia-heading--title-100 {
  padding-right: 8rem;
}
</style>
